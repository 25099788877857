import React from "react"
import { graphql } from "gatsby"

const Demo = ({ data }) => {
  return (
    <div>
      <p>{data.datoCmsPaginaDeInicio.subtitulo}</p>
    </div>
  )
}

export const query = graphql`
  query {
    datoCmsPaginaDeInicio {
      titulo
      subtitulo
      texto
    }
  }
`

export default Demo
